<template>
  <div class="page">
    <router-view />
    <van-tabbar route active-color="#ff571e" inactive-color="#8686aa" class="main_tabbar">
      <van-tabbar-item to="/index" icon="wap-home-o">
        <span>{{ $t('common.home') }}</span>
      </van-tabbar-item>
      <van-tabbar-item to="/assets/recharge" icon="refund-o"
        ><span>{{ $t('czhi.title') }}</span>
      </van-tabbar-item>
      <van-tabbar-item icon="service-o" @click="tokefu"><span>{{ $t('home.serverurl') }}</span>
      </van-tabbar-item>
      <van-tabbar-item to="/order" icon="description-o">
        <span>订单</span>
        <template #icon="props">
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/assets" icon="contact-o">
        <span>{{ $t('home.account') }}</span>
        <template #icon="props">
		</template>
	  </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: {
        sy: {
          active: require('../assets/img/sy-xz.png'),
          inactive: require('../assets/img/sy.png')
        },
        hq: {
          active: require('../assets/img/hq-xz.png'),
          inactive: require('../assets/img/hq.png')
        },
        jy: {
          active: require('../assets/img/jy-xz.png'),
          inactive: require('../assets/img/jy.png')
        },
        zc: {
          active: require('../assets/img/zc-xz.png'),
          inactive: require('../assets/img/zc.png')
        }
      },
	  serverurl:""
    }
  },
  mounted() {
  	this.getdata();
  },
  methods: {
	  tokefu(){
		  if(this.serverurl){
			  window.location.href = this.serverurl
		  }
	  },
	  async getdata() {
	    const { data } = await this.$http.get('/home/home/index')
	    if (data) {
	      if (data.code === 200) {
	        this.serverurl = data.data.serverurl
	      }
	    }
	  },
  }
}
</script>
<style lang="less">
.page {
  //background: #f7f7f7;
}

</style>
